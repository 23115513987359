import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"
const MySwal = withReactContent(Swal)

export const confirmationModal = ({text, action, onSuccess, title, icon, showCancelButton, confirmButtonText}) => {

    return MySwal.fire({
        title: title || intl.formatMessage({id: 'Are you sure?'}),
        text,
        icon: icon || 'warning',
        showCancelButton: showCancelButton || true,
        confirmButtonText: confirmButtonText || intl.formatMessage({id: 'Yes, I confirm'}),
        cancelButtonText: intl.formatMessage({id: 'Cancel'}),
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
    }).then(async (result) => {
        if (result.value) {
            const response = await action()

            if (response) {
                MySwal.fire({
                    icon: 'error',
                    title: intl.formatMessage({id: 'Error'}),
                    text: intl.formatMessage({id: 'There was an error while trying to perform this action. Please contact the administrator.'}),
                    customClass: {
                        confirmButton: 'btn btn-error'
                    }
                })
            } else {
                if (onSuccess) onSuccess()
            }
        }
    })
}
