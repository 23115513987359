import { Ability } from "@casl/ability"
import { initialAbility } from "./initialAbility"

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this


export const abilityCan = (action, subject) => {

  const userData = JSON.parse(localStorage.getItem("mozrestApp:userData"))

// const existingAbility = userData ? userData.ability : null


  const existingAbility = userData ? userData.ability : []


  return !!existingAbility.find(
    (ability) => ability.action === action && ability.subject === subject
  )
}


export default () => {
  const userData = JSON.parse(localStorage.getItem("mozrestApp:userData"))
  const existingAbility = userData ? userData.ability : null
  return new Ability(existingAbility || initialAbility)
} 
