// ** SuperAdmin user ability
export const superadminAbility = [
  {
    action: "read",
    subject: "home"
  },
  {
    action: "view",
    subject: "reservations:analytics"
  },
  {
    action: 'edit',
    subject: 'licenses:meta'
  },
  {
    action: 'edit',
    subject: 'licenses:listingAndReview'
  },
  {
    action: 'edit',
    subject: 'licenses:tripadvisor'
  },
  {
    action: 'edit',
    subject: 'customers:companies'
  },
  {
    action: 'edit',
    subject: 'customers:venues'
  },
  {
    action: "edit",
    subject: "settings:widgets"
  },
  {
    action: 'edit',
    subject: 'customers:venues'
  },
  {
    action: 'edit',
    subject: 'partners:rms'
  },
  {
    action: 'edit',
    subject: 'partners:booking-channels'
  },
  {
    action: 'edit',
    subject: 'partners:distributors'
  },
  {
    action: "edit",
    subject: "settings:users"
  },
  {
    action: "view",
    subject: "settings:account"
  },
  {
    action: "filter",
    subject: "venues:selection"
  },
  {
    action: "edit",
    subject: "settings:listing-review"
  }
]
