import { countries } from '@configs/Constants'
import Geocode from "react-geocode"
import { storage } from '@utils'
import _ from "lodash"
import buildAbilities from "./buildAbilities"
import { superadminAbility } from "../config/acl/superadminAbility"
import { adminAbility } from "../config/acl/adminAbility"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatCurrency = (value, currency) => {
  return `${(value / 100).toFixed(2)} ${currency}`
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const getUserData = () => JSON.parse(localStorage.getItem('persist:mozrest-hub'))
export const isUserLoggedIn = () => {
  const user = getUserData()
  return localStorage.getItem('mozrestApp:token') && !!user && user.user.length > 0
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (_.indexOf(userRole, 'ROLE_ADMIN') || _.indexOf(userRole, 'ROLE_MANAGER') || _.indexOf(userRole, 'ROLE_VENUE') || _.indexOf(userRole, 'ROLE_RESERVATION_PORTAL')) return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const renderCountry = country => {
  return countries[_.findIndex(countries, ['value', country])].label
}

export const getUserEmail = () => {
  const userData = JSON.parse(storage.get('mozrestApp:userData'))
  return userData.adminEmail ?? userData.email
}

export const getClientEmail = () => {
  const userData = JSON.parse(storage.get('mozrestApp:userData'))
  return userData.email ?? ''
}

export const getUserFullName = () => {
  const userData = JSON.parse(storage.get('mozrestApp:userData'))
  return userData.adminFullName ?? `${userData.name} ${userData.lastName ?? ''}`
}

export const getUserCompany = () => {
  const userData = JSON.parse(storage.get('mozrestApp:userData'))
  return userData.company.id
}

export const getUserCompanyAccessKey = () => {
  const userData = JSON.parse(storage.get('mozrestApp:userData'))
  return userData.company?.accessKey
}
export const getUserCompanyRms = () => {
  //TO FIX
  const userData = JSON.parse(storage.get('mozrestApp:userData'))
  return userData.partner
}

export const getCompanyCountry = () => {
  //TO FIX
  const userData = JSON.parse(storage.get('mozrestApp:userData'))
  return userData.company.country || 'es'
}

export const getUserRoles = () => {
  const userData = JSON.parse(storage.get('mozrestApp:userData'))
  return userData.roles
}

export const isBookingChannel = () => {
  return _.indexOf(getUserRoles(), 'ROLE_RESERVATION_PORTAL') >= 0
}

export const isSuperAdmin = () => {
  return _.indexOf(getUserRoles(), 'ROLE_SUPER_ADMIN') >= 0
}

export const totalVenues = () => {
  return localStorage.getItem('mozrestApp:totalVenues')
}

export const hasReviewsAnalyticsPermissions = () => {
  const userData = JSON.parse(storage.get('mozrestApp:userData'))
  return userData.permission?.rAnalytics === 'w'
}

export const hasListingAnalyticsPermissions = () => {
  const userData = JSON.parse(storage.get('mozrestApp:userData'))
  return userData.permission?.lAnalytics === 'w'
}

export const hasReservationsAnalyticsPermissions = () => {
  const userData = JSON.parse(storage.get('mozrestApp:userData'))
  return userData.permission?.bAnalytics === 'w'
}

export const venueType = (type = 'many') => {
  const companyType = localStorage.getItem('mozrestApp:companyType')
  if (companyType === 'restaurant' && type === 'one') return 'restaurant'
  if (companyType === 'restaurant' && type === 'many') return 'restaurants'
  if (companyType === 'garage' && type === 'one') return 'garage'
  if (companyType === 'garage' && type === 'many') return 'garages'
  return 'restaurants'
}

export const getLtnLngFromAddress = async (address) => {
  Geocode.setApiKey(process.env.REACT_APP_MAPS_API_KEY)
  let latitude = 0
  let longitude = 0
  if (address) {
    await Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location
        latitude = lat
        longitude = lng
      },
      (error) => {
        console.error(error)
      }
    )

    return ({ latitude, longitude })
  }
}

export const getAbilities = (data) => {
  const { roles, permission } = data
  if (_.indexOf(roles, 'ROLE_SUPER_ADMIN') >= 0) {
    return superadminAbility
  }
  if (_.indexOf(roles, 'ROLE_ADMIN') >= 0 && !permission) {
    console.log("admin ability")
    return adminAbility
  }
  return buildAbilities(permission)
}

export const generateRandomPass = () => {
  const char = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890'
  const random = Array.from(
    { length: 20 },
    () => char[Math.floor(Math.random() * char.length)]
  )
  return random.join("")
}

export const clearCookies = () => {
  storage.clear('mozrestApp:token')
  storage.clear('mozrestApp:refreshtoken')
  storage.clear('persist:mozrest-hub')
  storage.clear('mozrestApp:userData')
  storage.clear('mozrestApp:companyType')
  storage.clear('mozrestApp:totalVenues')
}

const bcStatsColor = {
  RWG: "#2b7de9",
  FB: "#4267B2",
  IG: "#E1306C",
  PERE: "#E8A014",
  MG: "#bd2333",
  TOL: "#111111",
  GON: "#1DB7CE",
  FORM: "#006bff",
  E2E: "#f9ab27",
  ZONAL: "#28A4A0"
}

const defaulColor = "#1f155a"

export const getColor = (rp) => {
  return bcStatsColor[rp] || defaulColor
}
