//Template config options
const themeConfig = {
    layout: {
        isRTL: false,
        skin: 'light', // light, dark, bordered, semi-dark
        routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
        contentWidth: 'full', // full, boxed
        menu: {
            isHidden: false,
            isCollapsed: false
        },
        customizer: false,
        scrollTop: true // Enable scroll to top button
    }
}

export default themeConfig
