const defaultAbilities = [
  {
    action: "read",
    subject: "home"
  },
  {
    action: "view",
    subject: "settings:account"
  },
  {
    action: "filter",
    subject: "venues:selection"
  }
]

const permisionMapper = {
  bChannelManagement: {
    action: "edit",
    subject: "reservations:channel-management"
  },
  bAnalytics: {
    action: "view",
    subject: "reservations:analytics"
  },
  lConnectionManagement: {
    action: "edit",
    subject: "listing:connection-management"
  },
  lAnalytics: {
    action: "view",
    subject: "listing:analytics"
  },
  rCommentsManagement: {
    action: "edit",
    subject: "reviews:comments-management"
  },
  rAnalytics: {
    action: "view",
    subject: "reviews:analytics"
  },
  sVenues: {
    action: "edit",
    subject: "settings:venues"
  },
  sUsers: {
    action: "edit",
    subject: "settings:users"
  },
  sGroups: {
    action: "edit",
    subject: "settings:groups"
  },
  sNotifications: {
    action: "edit",
    subject: "settings:notifications"
  },
  sReplyTemplates: {
    action: "edit",
    subject: "settings:reply-templates"
  },
  pResbook: {
    action: "view",
    subject: "resbook:dashboard"
  },
  pResbookSettings: {
    action: "view",
    subject: "resbook:configuration"
  }
}

const buildAbilities = (permissions = {}, isPOS) => {
  let abilities = Object.keys(permissions)
    .filter((permission) => permissions[permission] !== "n")
    .map((permission) => permisionMapper[permission])

  if (!isPOS) {
    abilities = abilities.filter((ability) => ability.subject.indexOf('resbook') === -1)
  }

  return [...defaultAbilities, ...abilities]
}

export default buildAbilities
