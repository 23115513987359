import { create } from 'apisauce'
import _ from 'lodash'
import { handlePromise, storage } from '@utils'
import store from '@store'
import { UserTypes } from '@store/reducers/User'
import AuthService from '@services/Auth'

const TWO_SECONDS_IN_MILISECONDS = 2000

const api = create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000
})

const refreshApi = create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000
})

api.addRequestTransform((request) => {
  const accessToken = storage.get('mozrestApp:token')
  if (!request['headers[public]']) {
    if (accessToken) request.headers.Authorization = `Bearer ${accessToken}`
  }
})
function isTokenExpired() {
  const accessToken = storage.get('mozrestApp:token')
  return accessToken && accessToken.expiresIn < Date.now()
}
async function checkTokenValidity() {
  if (isTokenExpired()) {

    const refreshed = await refreshAccessToken()
    if (!refreshed) {
      console.log("Not has refresh token")
      location.replace('/login')
      store.dispatch({ type: UserTypes.logoutUser })
      throw new Error('Token refresh failed')
    }
  }
}

api.addAsyncResponseTransform(async (response) => {
  const { data, ok } = response

  if (!ok) {
    let errors = ''
    if (response && response.status === 401
    /* && data && ( data.error === 'invalid_grant' || data.error === 'access_denied' ) */) {
      const originalConfig = response.config
      const refreshtoken = storage.get('mozrestApp:refreshtoken')
      storage.clear('mozrestApp:refreshtoken')
      if (refreshtoken && !originalConfig._retry) {
        try {
          originalConfig._retry = true
          const refreshResponse = await refreshApi.post('/v2/token', {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            grant_type: 'refresh_token',
            refresh_token: refreshtoken
          })
          if (refreshResponse.status !== 200) {
            throw new Error(refreshResponse?.error_description || 'Error refreshing token')
          }
          storage.set('mozrestApp:token', refreshResponse.data.access_token)
          storage.set('mozrestApp:refreshtoken', refreshResponse.data.refresh_token)
          storage.set('mozrestApp:totalVenues', refreshResponse.data.totalVenues)
          const retryResponse = await api.any(originalConfig)
          Object.assign(response, retryResponse)
        } catch (error) {
          location.replace('/login')
          return store.dispatch({ type: UserTypes.logoutUser })
        }
      }

      if (!refreshtoken && !originalConfig._retry) {
        originalConfig._retry = true
        await new Promise((resolve) => setTimeout(resolve, TWO_SECONDS_IN_MILISECONDS))
        const retryResponse = await api.any(originalConfig)
        return Object.assign(response, retryResponse)
      }

      if (!refreshtoken && location.pathname !== '/login' && location.pathname !== '/login-hash') {
        storage.clear('mozrestApp:token')
        storage.clear('mozrestApp:refreshtoken')
        storage.clear('persist:mozrest-hub')
        storage.clear('mozrestApp:userData')
        storage.clear('mozrestApp:companyType')
        storage.clear('mozrestApp:totalVenues')
        location.replace('/login')
        // return store.dispatch({ type: UserTypes.logoutUser })
      }

    }
    api.addRequestTransform(async (request) => {

      await checkTokenValidity()

      const accessToken = storage.get('mozrestApp:token')
      if (!request['headers[public]']) {
        if (accessToken) request.headers.Authorization = `Bearer ${accessToken.token}`
      }
    })
    checkTokenValidity()
    if (data) {
      if (data.form) {
        errors = {}
        _.map(data.form.errors, (error, key) => {
          if (!_.isEmpty(error)) {
            errors[key] = error.join('\n')
          }
        })
      } else if (data.errors) {
        if (_.isObject(data.errors) && !_.isArray(data.errors)) {
          errors = []
          _.map(data.errors, (error) => {
            if (_.isString(error)) errors.push(error)
            else if (_.isArray(errors)) errors.push(error.join('\n'))
          })
          errors = errors.join('\n')
        } else if (_.isArray(data.errors)) {
          errors = data.errors.join('\n')
        } else {
          errors = data.errors
        }
      } else if (data.message) errors = data.message
      else if (data.error_description) errors = data.error_description
      else errors = data
    }

    if (!errors || _.isEmpty(errors)) errors = 'apiError'
    if (response.config.method === 'get' && !_.isString(errors)) response.errors = 'apiError'
    else response.errors = errors
  }
})

export default api
